import React from 'react'
import Layout from '../components/page-objects/Layout'
import Header from '../components/page-objects/Header'
import Headerimage from "../img/404.svg";
import Spacer from "../components/ui-objects/Spacer"
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <Header
      headerImage={Headerimage}
      showPromoBlock={false}
      backgroundPositie="0"
    />
    <Spacer size="xxl" />
    <div style={{ textAlign: "center" }}>
      <h1>404 NOT FOUND</h1>
      <p>
        Helaas is deze pagina niet beschikbaar! Klik&nbsp;
        <Link to="/">hier</Link>
        &nbsp;om verder te gaan...
      </p>
    </div>
    <Spacer size="xxl" />
  </Layout>
);

export default NotFoundPage
